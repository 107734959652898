import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ApiTester = () => {
    const [url, setUrl] = useState('');
    const [method, setMethod] = useState('GET');
    const [requestData, setRequestData] = useState('');
    const [response, setResponse] = useState(null);
    const [wsMessages, setWsMessages] = useState([]);
    const [isWebSocket, setIsWebSocket] = useState(false);
    const [ws, setWs] = useState(null);  // WebSocket instance state

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isWebSocket) {
            connectWebSocket(url);
        } else {
            try {
                const result = await axios({
                    method,
                    url,
                    data: requestData ? JSON.parse(requestData) : {}
                });
                setResponse(JSON.stringify(result.data, null, 2));
            } catch (error) {
                setResponse(`Error: ${error.message}`);
            }
        }
    };

    const connectWebSocket = (url) => {
        const newWs = new WebSocket(url);
        setWs(newWs);
        newWs.onopen = () => {
            setWsMessages((prev) => [...prev, 'WebSocket connected']);
        };
        newWs.onmessage = (event) => {
            setWsMessages((prev) => [...prev, event.data]);
        };
        newWs.onclose = () => {
            setWsMessages((prev) => [...prev, 'WebSocket disconnected']);
        };
        newWs.onerror = (error) => {
            setWsMessages((prev) => [...prev, `WebSocket error: ${error.message}`]);
        };
    };

    useEffect(() => {
        return () => {
            // Clean up WebSocket connections if any
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
        };
    }, [ws]);

    return (
        <div className="container mt-5">
            <h2>API Tester Tool</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="requestType" className="form-label">Request Type</label>
                    <select className="form-select" id="requestType" value={isWebSocket} onChange={e => setIsWebSocket(e.target.value === 'true')}>
                        <option value="false">HTTP Request</option>
                        <option value="true">WebSocket</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="method" className="form-label">HTTP Method</label>
                    <select className="form-select" id="method" value={method} onChange={e => setMethod(e.target.value)} disabled={isWebSocket}>
                        <option>GET</option>
                        <option>POST</option>
                        <option>PUT</option>
                        <option>DELETE</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="url" className="form-label">API URL</label>
                    <input type="text" className="form-control" id="url" value={url} onChange={e => setUrl(e.target.value)} />
                </div>
                <div className="mb-3" hidden={isWebSocket}>
                    <label htmlFor="requestData" className="form-label">Request Data (JSON)</label>
                    <textarea className="form-control" id="requestData" rows="3" value={requestData} onChange={e => setRequestData(e.target.value)}></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Send Request</button>
            </form>
            {response && !isWebSocket && (
                <div className="mt-3">
                    <h4>Response:</h4>
                    <pre>{response}</pre>
                </div>
            )}
            {isWebSocket && wsMessages.length > 0 && (
                <div className="mt-3">
                    <h4>WebSocket Messages:</h4>
                    <ul>
                        {wsMessages.map((msg, index) => (
                            <li key={index}>{msg}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ApiTester;
