import React from 'react';
import { Link } from 'react-router-dom';
import Clock from '../Clock';
import { useUser } from '../../contexts/UserContext';
import './Navbar.css'; // Import the CSS file for additional styles

const Navbar = () => {
    const { user, logout } = useUser(); // Use the useUser hook to access the user context

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light custom-navbar">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img src={`${process.env.PUBLIC_URL}/assets/bootstrap/images/contra_logo_184x150.svg`} alt="Contra Logo" className="navbar-logo" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to="/" className="nav-link active" aria-current="page">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/access" className="nav-link">Access System</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/help" className="nav-link disabled" aria-disabled="true">Help</Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <span className="nav-link"><Clock /></span>
                        </li>
                        {user && (
                            <>
                                <li className="nav-item">
                                    <span className="nav-link">Hello, {user.full_name}!</span>
                                </li>
                                <li className="nav-item">
                                    <button className="btn btn-outline-danger btn-sm" onClick={() => logout()}>Logout</button>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
