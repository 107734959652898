import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext'; // Correct relative path to the context
import ActivePositions from '../components/ActivePositions/ActivePositions'; // Import ActivePositions component
import FundsAndMargins from '../components/FundsAndMargins/FundsAndMargins'; // Import FundsAndMargins component
import Control from '../components/Control/Control'; // Import Control component
import StrategyHealth from '../components/StrategyHealth/StrategyHealth'; // Import StrategyHealth component
import './Dashboard.css'; // Import the CSS file for additional styles

const Dashboard = () => {
    const { user, logout } = useUser(); // Use the useUser hook to access the user context
    const navigate = useNavigate(); // useNavigate called at the top level
    const [view, setView] = useState('positions'); // State to toggle between views

    useEffect(() => {
        if (!user) {
            navigate('/access'); // Redirect to login if no user is found
        }
    }, [user, navigate]); // useEffect to handle redirection based on user existence

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // This is required for some browsers to show the prompt
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    if (!user) {
        return null; // Early return to render nothing if user is not available
    }

    return (
        <div>
            <div className="container mt-4">
                <div className="btn-group btn-group-sm mb-3" role="group">
                    <button type="button" className={`btn btn-sm btn-${view === 'positions' ? 'primary' : 'secondary'}`} onClick={() => setView('positions')}>Positions</button>
                    <button type="button" className={`btn btn-sm btn-${view === 'fundsmargins' ? 'primary' : 'secondary'}`} onClick={() => setView('fundsmargins')}>Funds</button>
                    <button type="button" className={`btn btn-sm btn-${view === 'control' ? 'primary' : 'secondary'}`} onClick={() => setView('control')}>Control</button>
                    <button type="button" className={`btn btn-sm btn-${view === 'strategyhealth' ? 'primary' : 'secondary'}`} onClick={() => setView('strategyhealth')}>Health</button>
                </div>

                {view === 'positions' && <ActivePositions />}
                {view === 'fundsmargins' && <FundsAndMargins />}
                {view === 'control' && <Control />}
                {view === 'strategyhealth' && <StrategyHealth />}
            </div>
        </div>
    );
};

export default Dashboard;
