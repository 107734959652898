import React, { useState, useEffect } from 'react';

// At the top of your component or in a separate utilities file
import axiosInstance from '../../src/axiosConfig'; // Import the configured Axios instance

const HomePage = () => {
    const [backendMessage, setBackendMessage] = useState('');

    useEffect(() => {
        const fetchMessage = async () => {
            try {
                const response = await axiosInstance.get('/api/hello');
                setBackendMessage(response.data.message);  // Assuming the backend sends a response in { message: "Hello World" }
            } catch (error) {
                setBackendMessage('Failed to fetch data from backend');
            }
        };

        fetchMessage();
    }, []);

    return (
        <div className="container">
            <header className="py-5">
                <h1>Contra - Insights</h1>
                <br />
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <h5 className="card-header">System Health</h5>
                            <div className="card-body">
                                <h5 className="card-title">APIs</h5>
                                {/* Conditionally render alerts based on backendMessage */}
                                {
                                    backendMessage === 'Hello World!' ? (
                                        <div className="alert alert-success" role="alert">
                                            Insights system is healthy - {backendMessage}
                                        </div>
                                    ) : (
                                        <div className="alert alert-danger" role="alert">
                                            Insights system is unhealthy. Reach out to your IT Admin. {backendMessage}
                                        </div>
                                    )
                                }
                                <a href="/" className="btn btn-primary">Report Issue</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <h5 className="card-header">System Maintenance</h5>
                            <div className="card-body">
                                <h5 className="card-title">Scheduled Downtime</h5>
                                <p className="card-text">Feature yet to be released.
                                Wait until next release.</p>
                                <a href="/" className="btn btn-primary">Go somewhere</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                    <div className="card">
                        <h5 className="card-header">Access System</h5>
                        <div className="card-body">
                            <h5 className="card-title">Insights App</h5>
                            <p className="card-text">Click on the botton to Access Insights Application</p>
                            <a href="/access" className="btn btn-primary">Insights App</a>
                        </div>
                    </div>
                    </div>
                </div>
                <h2>Legal Notice - Restricted Access</h2>
                <h3>Accessing the System</h3>
                <p className="lead">Welcome to Contra - Insights. This system is secured and restricted to authorized users only. If you are an authorized user and require access to this system, please contact our IT department to initiate the access process. Our IT personnel will guide you through the necessary steps to securely log in and use the system.</p>
            </header>
            <div className="row">
                <div className="col-md-4">
                    <h2>Important Notice</h2>
                    <p>You are attempting to access a private system. This system is for the exclusive use of authorized staff members only. Unauthorized access or use of this system is strictly prohibited and constitutes a violation of applicable laws.</p>
                </div>
                <div className="col-md-4">
                    <h2>Access Restrictions</h2>
                    <p>Access to this system is monitored and recorded for administrative and security purposes. Any unauthorized access, use, or modification of this system or of the data contained herein, or in transit to/from this system, may result in severe civil and criminal penalties. By continuing to use this system, you indicate your awareness of and consent to these terms and conditions of use.</p>
                </div>
                <div className="col-md-4">
                    <h2>Consent To Monitoring</h2>
                    <p>If you are not an authorized user of this system or do not consent to continued monitoring, please exit the system at this time. Continued use of this system implies consent to monitoring and an understanding that recorded evidence of unauthorized access or use may be used for administrative, legal, or criminal actions.</p>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
