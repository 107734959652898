import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const logout = () => {
        setUser(null);  // Clear user data
        localStorage.removeItem('user');  // Optional: remove user data from local storage if used
        // Add any other cleanup you might need here
    };

    // Ensure values passed to Provider include 'logout'
    return (
        <UserContext.Provider value={{ user, setUser, logout }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
