import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';  // Import useUser from your UserContext

import axiosInstance from '../../src/axiosConfig'; // Import the configured Axios instance

const AccessSystemPage = () => {
    // State variables for managing user input and authentication status
    const { setUser } = useUser();  // Retrieve setUser from context
    const [secretKey, setSecretKey] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isValidKey, setIsValidKey] = useState(false);
    const [authError, setAuthError] = useState('');
    const navigate = useNavigate();

    // Handles updating the secret key input
    const handleKeyChange = (event) => {
        setSecretKey(event.target.value);
    };

    // Handles updating the email input
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    // Handles updating the password input
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    // Validates the secret key by making an API call to the server
    const validateKey = async () => {
        try {
            const response = await axiosInstance.post('/api/validateKey', { secretKey });
            if (response.data.isValid) {
                setIsValidKey(true);
                setAuthError('');
            } else {
                throw new Error('Invalid Secret Key');
            }
        } catch (err) {
            setAuthError(err.message);
            setIsValidKey(false);
        }
    };

    // Handles the login process after the secret key is validated
    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await axiosInstance.post('/api/login', {
                email_address: email,
                pwd: password
            });
            if (response.data.IsValidUser) {
                setUser(response.data);  // Set the user data in context
                navigate('/dashboard'); // Redirect to the Dashboard on successful login
            } else {
                throw new Error('Invalid login credentials');
            }
        } catch (err) {
            setAuthError(err.message);
        }
    };

    // Handles the submission of the secret key form
    const handleSubmitKey = (event) => {
        event.preventDefault();
        validateKey();
    };

    // Conditionally renders the login form if the secret key is valid
    if (isValidKey) {
        return (
            <div className="container" style={{ marginTop: '20px' }}>
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-4">
                        <div className="card">
                            <h5 className="card-header">Login</h5>
                            <div className="card-body">
                                <form onSubmit={handleLogin}>
                                    <div className="mb-3">
                                        <label htmlFor="InputEmail1" className="form-label">Email address</label>
                                        <input type="email" className="form-control" id="InputEmail1" value={email} onChange={handleEmailChange} aria-describedby="emailHelp" />
                                        <div id="emailHelp" className="form-text">Enter your registered email address.</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                        <input type="password" className="form-control" id="exampleInputPassword1" value={password} onChange={handlePasswordChange} />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Login</button>
                                    {authError && <p style={{ color: 'red' }}>{authError}</p>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // Renders the initial form to enter the secret key
    return (
        <div className="container" style={{ paddingTop: '50px' }}>
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <form onSubmit={handleSubmitKey}>
                        <div className="mb-3">
                            <input
                                type="password"
                                className="form-control"
                                value={secretKey}
                                onChange={handleKeyChange}
                                placeholder="Enter Secret Key"
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">Validate Key</button>
                        {authError && <p style={{ color: 'red' }}>{authError}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AccessSystemPage;
