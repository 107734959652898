// Control.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosConfig';
import './Control.css';

const Control = () => {
    const [strategies, setStrategies] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [underlyings, setUnderlyings] = useState([]);
    const [selectedStrategy, setSelectedStrategy] = useState('');
    const [selectedAccount, setSelectedAccount] = useState('');
    const [selectedSymbol, setSelectedSymbol] = useState('');
    const [strategyDesc, setStrategyDesc] = useState('');
    const [accountName, setAccountName] = useState('');
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmChecked, setConfirmChecked] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [responseColor, setResponseColor] = useState('');

    useEffect(() => {
        const fetchStrategies = async () => {
            try {
                const response = await axiosInstance.get('/api/strategies');
                setStrategies(response.data);
            } catch (error) {
                console.error('Error fetching strategies:', error);
            }
        };

        const fetchAccounts = async () => {
            try {
                const response = await axiosInstance.get('/api/accounts');
                setAccounts(response.data);
            } catch (error) {
                console.error('Error fetching accounts:', error);
            }
        };

        fetchStrategies();
        fetchAccounts();
    }, []);

    const handleStrategyChange = (e) => {
        const selected = e.target.value;
        setSelectedStrategy(selected);
        const strategy = strategies.find(strategy => strategy.strategy_name === selected);
        setStrategyDesc(strategy ? strategy.strategy_desc : '');
        setSelectedAccount(''); // Reset account and underlying selections
        setSelectedSymbol('');
        setUnderlyings([]);
    };

    const handleAccountChange = (e) => {
        const selected = e.target.value;
        setSelectedAccount(selected);
        const account = accounts.find(account => account.app_name === selected);
        setAccountName(account ? account.account_name : '');
        setSelectedSymbol('');
        fetchUnderlyings(selectedStrategy);
    };

    const fetchUnderlyings = async (strategyName) => {
        try {
            const response = await axiosInstance.get(`/api/underlying?strategy_name=${strategyName}`);
            setUnderlyings(response.data);
        } catch (error) {
            console.error('Error fetching underlyings:', error);
        }
    };

    const handleSymbolChange = (e) => {
        const selected = e.target.value;
        setSelectedSymbol(selected);
    };

    const handleCreateInterrupt = () => {
        setShowConfirmDialog(true);
    };

    const handleCancel = () => {
        setShowConfirmDialog(false);
        setSelectedStrategy('');
        setSelectedAccount('');
        setSelectedSymbol('');
        setStrategyDesc('');
        setAccountName('');
    };

    const handleProceed = async () => {
        if (!confirmChecked) return;

        try {
            const response = await axiosInstance.post('/api/createInterrupt', null, {
                params: {
                    app_name: selectedAccount,
                    strategy_name: selectedStrategy,
                    symbol: selectedSymbol
                }
            });
            setResponseMessage(response.data.message);
            setResponseColor('green');
        } catch (error) {
            setResponseMessage('Error creating interrupt');
            setResponseColor('red');
        }

        setShowConfirmDialog(false);
        setConfirmChecked(false);
        setSelectedStrategy('');
        setSelectedAccount('');
        setSelectedSymbol('');
        setStrategyDesc('');
        setAccountName('');
    };

    return (
        <div className="control-container">
            <h2 className="dashboard-title">Control</h2>
            <div className="form-group mt-3">
                <label htmlFor="strategyAppNameSelect">Select Strategy</label>
                <select 
                    id="strategyAppNameSelect" 
                    className="form-control" 
                    value={selectedStrategy} 
                    onChange={handleStrategyChange}
                >
                    <option value="">Select a strategy</option>
                    {strategies.map((strategy, index) => (
                        <option key={index} value={strategy.strategy_name}>
                            {strategy.strategy_name}
                        </option>
                    ))}
                </select>
                {strategyDesc && <p className="description-text">{strategyDesc}</p>}
            </div>

            <div className="form-group mt-3">
                <label htmlFor="accountSelect">Select Account</label>
                <select 
                    id="accountSelect" 
                    className="form-control" 
                    value={selectedAccount} 
                    onChange={handleAccountChange}
                    disabled={!selectedStrategy} // Enable only if a strategy is selected
                >
                    <option value="">Select an account</option>
                    {accounts.map((account, index) => (
                        <option key={index} value={account.app_name}>
                            {account.app_name}
                        </option>
                    ))}
                </select>
                {accountName && <p className="description-text">{accountName}</p>}
            </div>

            <div className="form-group mt-3">
                <label htmlFor="symbolSelect">Select Underlying</label>
                <select 
                    id="symbolSelect" 
                    className="form-control" 
                    value={selectedSymbol} 
                    onChange={handleSymbolChange}
                    disabled={!selectedAccount || !underlyings.length} // Enable only if an account is selected and underlyings are available
                >
                    <option value="">Select a symbol</option>
                    {underlyings.map((underlying, index) => (
                        <option key={index} value={underlying.symbol}>
                            {underlying.symbol}
                        </option>
                    ))}
                </select>
            </div>

            {selectedSymbol && (
                <p className="description-text">
                    {selectedStrategy}, {selectedSymbol}
                </p>
            )}

            <button 
                className="btn btn-sm btn-primary mt-3" 
                onClick={handleCreateInterrupt}
                disabled={!selectedStrategy || !selectedAccount || !selectedSymbol}
            >
                Create Interrupt
            </button>

            {showConfirmDialog && (
                <div className="confirm-dialog">
                    <p>You are about to create an interrupt for <b>Strategy</b> - <i><u>{selectedStrategy}</u></i> on <b>Account</b> - <i><u>{selectedAccount}</u></i> for <b>Underlying</b> - <i><u>{selectedSymbol}</u></i></p>
                    <div>
                        <input 
                            type="checkbox" 
                            id="confirmCheckbox" 
                            checked={confirmChecked} 
                            onChange={() => setConfirmChecked(!confirmChecked)} 
                        />
                        <label htmlFor="confirmCheckbox">I know what I am doing</label>
                    </div>
                    <div className="dialog-buttons">
                        <button className="btn btn-secondary btn-sm" onClick={handleCancel}>Cancel</button>
                        <button 
                            className="btn btn-primary btn-sm" 
                            onClick={handleProceed} 
                            disabled={!confirmChecked}
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            )}

            {responseMessage && (
                <div className="response-message" style={{ color: responseColor }}>
                    {responseMessage}
                    <button 
                        className="btn btn-secondary btn-sm mt-2" 
                        onClick={() => setResponseMessage('')}
                    >
                        Ok
                    </button>
                </div>
            )}
        </div>
    );
};

export default Control;
