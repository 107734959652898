// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AccessSystemPage from './components/AccessSystemPage';
import ApiTester from './components/ApiTester';
import Dashboard from './pages/DashboardPage';
import { UserProvider } from './contexts/UserContext';  // Import the UserProvider

function App() {
  return (
      <UserProvider> {/* Wrap the Router with UserProvider */}
        <Router>
            <div>
                <Navbar />
                <Routes>
                    <Route path="/" element={<HomePage />} exact />
                    <Route path="/access" element={<AccessSystemPage />} />
                    <Route path="/api-tester" element={<ApiTester />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                </Routes>
                <div>
                  <br />
                  <Footer />
                </div>
            </div>
        </Router>
      </UserProvider>
  );
}

export default App;
