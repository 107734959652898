import React, { useState, useEffect } from 'react';

const Clock = () => {
    const [time, setTime] = useState(new Date()); // Initialize time with the current date and time

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000); // Update time every second

        return () => {
            clearInterval(timerID); // Cleanup the interval on component unmount
        };
    }, []);

    const tick = () => {
        setTime(new Date()); // Update time with the current date and time
    };

    const formatTime = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`; // Format: DD-MMM-YYYY HH:mm:ss
    };

    return (
        <div>
            {formatTime(time)}
        </div>
    );
}

export default Clock;
