// src/axiosConfig.js
import axios from 'axios';

// Log the current environment and base URL for debugging
console.log('Current NODE_ENV:', process.env.NODE_ENV);
console.log('API Base URL (Dev):', process.env.REACT_APP_API_BASE_URL_DEV);
console.log('API Base URL (Prod):', process.env.REACT_APP_API_BASE_URL_PROD);

const baseURL = process.env.NODE_ENV === 'production' 
    ? process.env.REACT_APP_API_BASE_URL_PROD 
    : process.env.REACT_APP_API_BASE_URL_DEV;

console.log('Using baseURL:', baseURL);

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        'x-api-key': process.env.REACT_APP_API_KEY // Ensure this is defined in your frontend .env file
    }
});

export default axiosInstance;
