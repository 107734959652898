import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosConfig';
import { formatNumberToIndianLocale } from '../../utils/formatNumber';
import './FundsAndMargins.css'; // Import CSS

const FundsAndMargins = () => {
    const [fundsMargins, setFundsMargins] = useState([]);

    const fetchFundsMargins = async () => {
        try {
            const response = await axiosInstance.get('/api/fundsmargins');
            setFundsMargins(response.data);
        } catch (error) {
            console.error('Error fetching funds and margins data:', error);
        }
    };

    useEffect(() => {
        fetchFundsMargins();
    }, []);

    const getCellStyle = (value) => {
        const number = parseFloat(value);
        if (number > 0) {
            return { color: 'green' };
        } else if (number < 0) {
            return { color: 'red' };
        } else {
            return { color: 'grey' };
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="dashboard-title">Funds and Margins</h2>
                <button 
                    className="btn btn-sm btn-outline-secondary" 
                    onClick={fetchFundsMargins}
                    style={{ height: '30px', fontSize: '0.8rem', padding: '2px 5px' }}>
                    Refresh
                </button>
            </div>
            <div className="table-responsive">
                <table className="table table-bordered table-striped table-sm">
                    <thead>
                        <tr>
                            <th>App Name</th>
                            <th className="text-right">Avl. Margin</th>
                            <th className="text-right">Used Margin</th>
                            <th className="text-right">Avl. Cash</th>
                            <th className="text-right">Coll. Liquid</th>
                            <th className="text-right">Coll. Equity</th>
                            <th className="text-right">Tot. Coll.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fundsMargins.map((fund, index) => {
                            const availableMargin = parseFloat(fund.available_margin);
                            const usedMargin = parseFloat(fund.used_margin);
                            const availableCash = parseFloat(fund.available_cash);
                            const collateralLiquidFunds = parseFloat(fund.collateral_liquid_funds);
                            const collateralEquity = parseFloat(fund.collateral_equity);
                            const totalCollateral = parseFloat(fund.total_collateral);

                            return (
                                <tr key={index}>
                                    <td>{fund.app_name}</td>
                                    <td className="text-right" style={getCellStyle(availableMargin)}>{formatNumberToIndianLocale(availableMargin)}</td>
                                    <td className="text-right" style={getCellStyle(usedMargin)}>{formatNumberToIndianLocale(usedMargin)}</td>
                                    <td className="text-right" style={getCellStyle(availableCash)}>{formatNumberToIndianLocale(availableCash)}</td>
                                    <td className="text-right" style={getCellStyle(collateralLiquidFunds)}>{formatNumberToIndianLocale(collateralLiquidFunds)}</td>
                                    <td className="text-right" style={getCellStyle(collateralEquity)}>{formatNumberToIndianLocale(collateralEquity)}</td>
                                    <td className="text-right" style={getCellStyle(totalCollateral)}>{formatNumberToIndianLocale(totalCollateral)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FundsAndMargins;
