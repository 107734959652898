import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer bg-light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 h-100 text-center text-lg-left my-auto">
                        <ul className="list-inline mb-2">
                            <li className="list-inline-item">
                                <Link to="/privacy">Privacy Policy</Link>
                            </li>
                            <li className="list-inline-item">⋅</li>
                            <li className="list-inline-item">
                                <Link to="/terms">Terms of Use</Link>
                            </li>
                            <li className="list-inline-item">⋅</li>
                            <li className="list-inline-item">
                                <Link to="/contact">Contact Us</Link>
                            </li>
                        </ul>
                        <p className="text-muted small mb-4 mb-lg-0">
                            © Contra Advisory LLP, 2024. All Rights Reserved.
                        </p>
                    </div>
                    <div className="col-lg-6 h-100 text-center text-lg-right my-auto">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item mr-3">
                                <a href="https://facebook.com">
                                    <i className="fa fa-facebook fa-2x fa-fw"></i>
                                </a>
                            </li>
                            <li className="list-inline-item mr-3">
                                <a href="https://twitter.com">
                                    <i className="fa fa-twitter fa-2x fa-fw"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://instagram.com">
                                    <i className="fa fa-instagram fa-2x fa-fw"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
