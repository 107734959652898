import React from 'react';
import './StrategyHealth.css';

const StrategyHealth = () => {
    return (
        <div>
            <h2 className="dashboard-title">Strategy Health</h2>
            {/* Add your strategy health component content here */}
        </div>
    );
};

export default StrategyHealth;
