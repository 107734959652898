// src/components/ActivePositions.js
import React, { useEffect, useState } from 'react';
import { useUser } from '../../contexts/UserContext';
import { formatNumberToIndianLocale } from '../../utils/formatNumber';
import './ActivePositions.css';

const ActivePositions = () => {
    const { user } = useUser();
    const [positions, setPositions] = useState([]);

    useEffect(() => {
        if (user) {
            const ws = new WebSocket(process.env.NODE_ENV === 'production'
                ? 'wss://insights.contraadvisory.net/ws'
                : 'ws://192.168.1.114:5000/ws');

            ws.onopen = () => {
                console.log('WebSocket connected');
            };

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                setPositions(data);
            };

            ws.onclose = () => {
                console.log('WebSocket disconnected');
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            return () => {
                ws.close();
            };
        }
    }, [user]);

    const groupedPositions = positions.reduce((acc, position) => {
        const { app_name } = position;
        if (!acc[app_name]) {
            acc[app_name] = [];
        }
        acc[app_name].push(position);
        return acc;
    }, {});

    const getCellStyle = (value) => {
        const number = parseFloat(value);
        if (number > 0) {
            return { color: 'green' };
        } else if (number < 0) {
            return { color: 'red' };
        } else {
            return { color: 'grey' };
        }
    };

    return (
        <div>
            <h2 className="dashboard-title">Active Positions</h2>
            <div className="row">
                {Object.keys(groupedPositions).map((appName) => (
                    <div className="col-12 mb-3" key={appName}>
                        <div className="card">
                            <div className="card-header">
                                {appName}
                            </div>
                            <div className="card-body p-1">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th>Symbol</th>
                                                <th className="text-right">Qty.</th>
                                                <th className="text-right">Avg.</th>
                                                <th className="text-right">LTP</th>
                                                <th className="text-right">PnL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groupedPositions[appName].map((position, index) => (
                                                <tr key={index}>
                                                    <td>{position.tradingsymbol}</td>
                                                    <td className="text-right" style={getCellStyle(position.quantity)}>{position.quantity}</td>
                                                    <td className="text-right">{position.average_price}</td>
                                                    <td className="text-right">{position.last_price}</td>
                                                    <td className="text-right" style={getCellStyle(position.pnl)}>{formatNumberToIndianLocale(position.pnl)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ActivePositions;
